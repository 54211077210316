<template>
  <div class="create-Select" ref="SelectBody" :class="{ active: isOpen }">
    <div
      class="select-box "
      :class="{ ErrorClass: isError, top: isTop, bottom: !isTop }"
    >
      <div
        ref="Select"
        class="Select word-type"
        :class="{ active: showValue === '' }"
        @click="Openoption"
      >
        {{ showValue == "" ? placeholder : showValue }}
        <div class="sel-Icon"></div>
      </div>
      <div class="option-box">
        <div class="option-describe" v-if="successContent != ''">
          {{ successContent }}
        </div>
        <div
          class="option word-type"
          :class="{
            active: selectValue == (item.value || item.label),
            nodata: item.value == 'value is error'
          }"
          v-for="(item, index) in option"
          :key="index + '-' + item.label"
          @click.stop="checkClick(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <small class="errorText" v-show="isError">{{ error_text }}</small>
    <!-- <div class="Popup"  @click="isOpen=false" v-show="isOpen"></div> -->
  </div>
</template>
<script>
var closeSelect;
export default {
  name: "PaSelect",
  props: {
    value: {
      //vue语法糖
      type: Object,
      default: () => {
        return { value: "", isError: false };
      }
    },
    placeholder: {
      //默认展示
      type: String,
      default: "请选择"
    },
    submitType: {
      type: Boolean,
      default: false
    },
    is_required: {
      //是否是必填
      type: Boolean,
      default: false
    },
    is_required_text: {
      //必填项内容为空时提示
      type: String,
      default: "选择框不能为空"
    },
    option: {
      //option列表
      type: Array,
      default: () => {
        return [
          {
            label: "无数据",
            value: "value is error"
          }
        ];
      }
    },
    isTop: {
      //是否向上展开
      type: Boolean,
      default: false
    },
    successContent: {
      //文本提示
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isOpen: false,
      selectValue: this.value.value,
      isError: this.value.isError,
      error_text: ""
    };
  },
  computed: {
    showValue: function() {
      let count = 0;
      for (let data of this.option) {
        count++;
        if (this.selectValue == (data.value || data.label)) {
          return data.label;
        }
      }
      this.selectValue = "";
      return "";
    }
  },
  watch: {
    isError(value) {
      this.$emit("input", {
        value: this.selectValue,
        isError: value
      });
    },
    value: {
      handler(value) {
        this.selectValue = value.value;
        this.isError = value.isError;
      },
      deep: true
    },
    selectValue(value) {
      this.$emit("input", {
        value,
        isError: this.isError
      });
      this.FormValidation();
    },
    submitType: {
      handler(value) {
        if (value) this.FormValidation();
      },
      immediate: true
    }
  },
  mounted() {
    document.addEventListener("click", this.closeSelect);
    // let offsetHeight = this.$refs.Select.offsetHeight;
    // // bottom: 0;
    // this.optionBoxStyle = `top:${offsetHeight}px;`;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeSelect);
  },
  methods: {
    Openoption() {
      this.isOpen = !this.isOpen;
      if (!this.isOpen) {
        this.FormValidation();
      }
    },
    closeSelect(e) {
      let dom = this.$refs.SelectBody;
      if (!dom.contains(e.target)) {
        this.isOpen = false;
      }
    },
    checkClick(item) {
      if (item.value !== "value is error") {
        this.selectValue = item.value || item.label;
      } else {
        this.selectValue = "";
      }
      this.isOpen = false;
    },
    FormValidation() {
      var res;
      let type = false;
      if (this.is_required) {
        if (
          this.selectValue == null ||
          this.selectValue.length < 1 ||
          this.selectValue === ""
        ) {
          type = true;
          this.error_text = this.is_required_text;
        }
      }
      this.isError = type;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.create-Select {
  margin-bottom: 20px;
  &.active {
    .select-box {
      &.top {
        .Select {
          border-radius: 0 0 8px 8px;
          border-color: $gray;
          // border-top: none;
          .sel-Icon {
            transform: rotate(180deg);
            background-image: url("../../assets/images/register/iconopen.png");
          }
        }
        .option-box {
          bottom: 54px;
          border-bottom: none;
          border-color: $gray;
          border-radius: 8px 8px 0 0;
          .option {
            border-bottom: 2px solid $gray;
            // border-radius: 8px 8px 0 0;
          }
          :first-child {
            border-bottom: 2px solid $gray;
          }
          :last-child {
            border-bottom: none;
          }
          :nth-child(1) {
            border-radius: 5.5px 5.5px 0 0;
          }
          .option-describe {
            // border-bottom: 2px solid $gray;
          }
        }
      }
      &.bottom {
        .Select {
          border-radius: 8px 8px 0 0;
          border-color: $gray;
          // border-bottom: none;
          .sel-Icon {
            transform: rotate(180deg);
            background-image: url("../../assets/images/register/iconopen.png");
          }
        }
        .option-box {
          top: 54px;
          border-top: none;
          border-color: $gray;
          border-radius: 0 0 8px 8px;
          :last-child {
            border-radius: 0 0 5.5px 5.5px;
          }
          .option:first-child {
            border-top: none;
          }
          .option-describe {
            // border-top: 2px solid $gray;
          }
          .option {
            border-top: 2px solid $gray;
            // border-radius: 0 0 8px 8px;
          }
        }
      }
      .Select {
        border-width: 2px;
      }
      .option-box {
        display: block;
      }
    }
  }
  .errorText {
    width: 100%;
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
    color: red;
    text-align: left;
  }
  .select-box {
    width: 100%;
    position: relative;
    &.ErrorClass {
      .Select {
        border-color: red;
      }
    }
    .Select {
      cursor: pointer;
      color: $black;
      font-family: $font;
      box-sizing: border-box;
      border: 1px solid $grays;
      font-size: 18px;
      color: $fontSize-gray;
      width: 100%;
      height: 54px;
      line-height: 54px;
      min-width: 100px;
      border-radius: 8px;
      padding: 0 33px 0 15px;
      text-align: left;
      position: relative;
      .sel-Icon {
        position: absolute;
        right: 18px;
        top: 0%;
        width: 15px;
        height: 100%;
        transform: rotate(360deg);
        background-image: url("../../assets/images/register/iconclose.png");
        background-position: center center;
        background-size: 15px auto;
        background-repeat: no-repeat;
        transition: 0.5s transform;
      }
      // &:focus {
      //   color: $black;
      //   border: 2px solid $gray;
      // }
      &.active {
        color: $fontSize-gray;
      }
    }
    .option-box {
      display: none;
      position: absolute;
      left: 0;
      font-family: $font;
      box-sizing: border-box;
      background-color: $fff;
      border: 2px solid $grays;
      font-size: 18px;
      width: 100%;
      z-index: 5000;
      overflow-x: hidden;
      max-height: 272px;
      .option-describe {
        width: 100%;
        color: $black;
        font-size: 14px;
        padding: 10px 15px;
        box-sizing: border-box;
        background-color: $orangeLight;
        line-height: 1.6;
        text-align: left;
      }

      &::-webkit-scrollbar {
        /*滚动条整体样式*/

        width: 0; /*高宽分别对应横竖滚动条的尺寸*/
        height: 0;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        // border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 2px #d5d5e6;
        // background: #d5d5e6;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        // -webkit-box-shadow: inset 0 0 2px #f5f5f9;
        // border-radius: 10px;
        // background: #f5f5f9;
        // cursor: pointer;
      }
      .option {
        width: 100%;
        height: 54px;
        line-height: 54px;
        padding: 0 5px 0 15px;
        box-sizing: border-box;
        text-align: left;
        &.nodata {
          justify-content: center;
          color: $grays;
        }
        &.active {
          color: $fff;
          background: $green;
        }
        &:hover {
          color: $fff;
          background: $green;
        }
      }
    }
  }
}
</style>
